exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-api-auth-js": () => import("./../../../src/pages/api/auth/[...].js" /* webpackChunkName: "component---src-pages-api-auth-js" */),
  "component---src-pages-book-appointment-js": () => import("./../../../src/pages/book-appointment.js" /* webpackChunkName: "component---src-pages-book-appointment-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-checkout-v-1-js": () => import("./../../../src/pages/checkout-v1.js" /* webpackChunkName: "component---src-pages-checkout-v-1-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-order-details-js": () => import("./../../../src/pages/order-details.js" /* webpackChunkName: "component---src-pages-order-details-js" */),
  "component---src-pages-order-order-details-js": () => import("./../../../src/pages/order/order-details.js" /* webpackChunkName: "component---src-pages-order-order-details-js" */),
  "component---src-pages-order-v-1-order-details-js": () => import("./../../../src/pages/order-v1/order-details.js" /* webpackChunkName: "component---src-pages-order-v-1-order-details-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-sign-in-js": () => import("./../../../src/pages/sign-in.js" /* webpackChunkName: "component---src-pages-sign-in-js" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-templates-test-page-js": () => import("./../../../src/templates/testPage.js" /* webpackChunkName: "component---src-templates-test-page-js" */)
}

