
export const footerNavigationItems = [
  {
    path: "/",
    title: "Home",
  },
  {
    path: "/about-us",
    title: "about us",
  },
  {
    path: "/services",
    title: "services",
  },
  {
    path: "/contact",
    title: "contact",
  },
  {
    path: "/book-appointment",
    title: "Book appointment",
  },
];
